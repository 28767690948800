.kep-login-facebook.metro {
    width: 100%;
}

.btnFacebook {
    width: 100%;
    /* border-radius: 4px; */
    background: #3b5998;
    color: white;
    border: 0px transparent;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem 2rem;
}

@media screen and (min-width:768px) {
    .btnFacebook {
        width: 56%;
        /* border-radius: 4px; */
        background: #3b5998;
        color: white;
        border: 0px transparent;
        text-align: center;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.4rem 2rem;

    }
}

@media screen and (min-width:992px) {
    .btnFacebook {
        width: 65%;
        /* border-radius: 4px; */
        background: #3b5998;
        color: white;
        border: 0px transparent;
        text-align: center;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.4rem 2rem;

    }

}


@media screen and (min-width:1200px) {
    .btnFacebook {
        width: 56%;
        /* border-radius: 4px; */
        background: #3b5998;
        color: white;
        border: 0px transparent;
        text-align: center;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.4rem 2rem;

    }

}

.btnGoogle {
    width: 100%;
    margin: 0 auto;
    width: 165px;
    height: 35px;
    border-radius: 4px;
    background: #db3236;
    color: white;
    border: 0px transparent;
    text-align: center;
    padding: 0.4rem 2rem;
}

@media screen and (min-width:768px) {

    .btnGoogle {
        width: 45%;
        margin: 0 auto;
        width: 165px;
        height: 35px;
        border-radius: 4px;
        background: #db3236;
        color: white;
        border: 0px transparent;
        text-align: center;
        padding: 0.4rem 2rem;
    }
}


@media screen and (min-width:992px) {

    .btnGoogle {
        width: 52%;
        margin: 0 auto;
        width: 165px;
        height: 35px;
        border-radius: 4px;
        background: #db3236;
        color: white;
        border: 0px transparent;
        text-align: center;
        padding: 0.4rem 2rem;
    }
}


@media screen and (min-width:1200px) {

    .btnGoogle {
        width: 56%;
        margin: 0 auto;
        width: 165px;
        height: 35px;
        border-radius: 4px;
        background: #db3236;
        color: white;
        border: 0px transparent;
        text-align: center;
        padding: 0.4rem 2rem;
    }
}

.btnFacebook:hover {
    background: #3b5998;
    opacity: 0.6;
}

.btnGoogle:hover {
    background: #db3236;
    opacity: 0.6;
}

.MuiDialogActions-root{
    justify-content: center !important;
}